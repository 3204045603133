import { isNil } from '@newfront-insurance/core';
import { LogoSpinner } from '@newfront-insurance/core-ui';
import { LoginBoundary } from '@newfront-insurance/next-auth';
import { useRouter } from '@newfront-insurance/next-router-provider';
import * as React from 'react';
import { useEffect } from 'react';

import { useRecentlyViewedAccount } from '../client/helpers/recently-viewed-account';

import { AuthProvider } from '@/client/providers/auth';

interface ClientRedirectProps {
  children: React.ReactNode;
}

/**
 * This component will render the child elements and attempt to redirect to the most recently viewed client.
 * If no recently viewed client exists, it will redirect to the first. If there are no accounts it will
 * show a full page message telling them they have no accounts.
 */
function RecentAccountRedirect({ children }: ClientRedirectProps): JSX.Element {
  const { pushRoute } = useRouter();
  const [accountUuid] = useRecentlyViewedAccount();

  useEffect(() => {
    if (accountUuid) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      pushRoute({
        pathname: '/[accountUuid]',
        query: {
          accountUuid,
        },
      });
    }
  }, [accountUuid, pushRoute]);

  // If the user has no accounts, show them an appropriate error message
  if (isNil(accountUuid)) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-white">
        <div className="max-w-md text-center">
          <h2 className="mb-4 text-xl font-semibold text-steel-500">No Client Accounts Available</h2>
          <p className="text-steel-400">Your user doesn&apos;t seem to have access to any accounts.</p>
          <p className="text-steel-400">Please contact Newfront Support for assistance.</p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

/**
 * This page will just redirect to the last viewed account. If the user has never viewed an
 * account before it will redirect them to the first one.
 */
export default function Home(): JSX.Element {
  return (
    <LoginBoundary authProvider={AuthProvider}>
      <RecentAccountRedirect>
        <LogoSpinner />
      </RecentAccountRedirect>
    </LoginBoundary>
  );
}
